import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;

    a {
        color: currentColor;
    }
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>5 Důvodů, Proč Navštívit Slovensko</Title>
            <Info>
                <Author>Simona</Author>
                <Date>16. 8. 2023</Date>
            </Info>

            <p>Slovensko, pro některé Čechy tak blízké a přitom tak vzdálené. Ruku na srdce, určitě ve svém okolí máte někoho, kdo na Slovensku za svůj život ještě nikdy nebyl (a nemyslíme tím právě narozené miminko vaší sestry).</p>

            <p>Znáte to, při výběru, kam vyrazit na dovolenou nebo prodloužený víkend si vždy raději vyberete Itálii, protože ty letenky byly za tak doboru cenu. A navíc, Slovensko je tak blízko, vždyť tam můžeme kdykoliv.</p>

            <p>Až na to, že nikdy :)</p>

            <p>A tak, abyste příště mohli zvolit variantu Slovensko, jsme pro vás vybrali 5 důvodů, proč se tam vydat.</p>

            <p>Slovensko je malebná země ležící uprostřed Evropy, nabízí návštěvníkům bohatství přírodních krás, historie a kultury. Od horských štítů po půvabné vesničky, tato země má hodně co nabídnout. Právě vám představujeme našich pět důvodů, proč byste přeci jen měli zvážit návštěvu Slovenska:</p>

            <BlogList>
                <li>
                    <h3>Krásy Vysokých Tater</h3>
                    <p>Vysoké Tatry, jedno z nejkrásnějších pohoří v Evropě, lákají návštěvníky svou malebnou krajinou a možnostmi pro outdoorové aktivity. Horolezci, turisté a milovníci přírody ocení množství stezek a cest vhodných pro různé úrovně dovedností. Chcete-li zažít úchvatný výhled na vrcholy hor, neměli byste vynechat lanovkou dostupné Štrbské pleso nebo skalní věže v Tatranské Lomnici.</p>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/slovakia/slovakia-1.jpg" alt="Vysoké Tatry"></StaticImage>
                    </StyledFigure>
                </li>
                <li>
                    <h3>Půvabné horské vesnice</h3>
                    <p>Slovensko je domovem mnoha malebných horských vesnic, které vás okouzlí svou autentičností a klidem. Obce jako Vlkolínec, zapísaná na seznamu UNESCO, vás vtáhnou do minulosti svými tradičními dřevěnými staveními a úzkými uličkami. Zde si můžete vychutnat tradiční slovenskou kuchyni a ochutnat místní speciality.</p>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/slovakia/slovakia-2.jpg" alt="Vlkolínec"></StaticImage>
                        <StyledFigcaption>Vlkolínec, zdroj pixabay: <a href="https://pixabay.com/cs/photos/vlkolinec-open-air-museum-vesnice-4740275/">https://pixabay.com/cs/photos/vlkolinec-open-air-museum-vesnice-4740275/</a>)</StyledFigcaption>
                    </StyledFigure>
                </li>
                <li>
                    <h3>Historická města</h3>
                    <p>Slovensko má také bohaté dějiny, které jsou zřetelné v jeho historických městech. Bratislava, hlavní město, nabízí pohled na impozantní Bratislavský hrad a malebnou starou část města. Košice, druhé největší město, zaujme návštěvníky svou katedrálou sv. Alžběty a úzkými uličkami plnými obchodů a kaváren.</p>
                </li>
                <li>
                    <h3>Kúpele a wellness</h3>
                    <p>Slovensko je známé také pro své termální prameny a kúpele. Města jako Piešťany a Rajecké Teplice lákají návštěvníky relaxací a léčebnými procedurami. Kromě toho najdete v těchto lázních moderní wellness služby, které vám pomohou uvolnit se a načerpat novou energii.</p>
                </li>
                <li>
                    <h3>Pestrá kultura a tradice</h3>
                    <p>Slovensko je zemí s bohatou kulturou a tradicemi. Můžete se zúčastnit folklorních festivalů, kde si můžete vychutnat lidovou hudbu, tance a tradiční řemesla. Navštívit můžete také různá muzea, která vám přiblíží historii země a jejího lidu.</p>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/slovakia/slovakia-3.jpg" alt="Folklorní představení"></StaticImage>
                        <StyledFigcaption>zdroj pixabay: <a href="https://pixabay.com/cs/photos/folkl%C3%B3r-tane%C4%8Dnice-kruh-kroje-tanec-996388/">https://pixabay.com/cs/photos/folkl%C3%B3r-tane%C4%8Dnice-kruh-kroje-tanec-996388/</a>)</StyledFigcaption>
                    </StyledFigure>
                </li>
            </BlogList>
            <p>Celkově vzato, Slovensko nabízí pestrou škálu zážitků od přírodních krás po kulturní poklady. Bez ohledu na to, zda jste dobrodruzi, milovníci historie nebo obdivovatelé přírodních krás, i tato malá země má co nabídnout každému.</p>
            <p>A pro méně zkušené cestovatele nebo starší ročníky, kteří mají strach z jazykové bariéry je tu ještě jeden důvod navíc a to ten, že na Slovensku se vždy domluvíte.</p>
            <p>Tak vzhůru do světa!</p>

        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
